import Hidden from '@material-ui/core/Hidden'
import React from 'react'
import styled from 'styled-components'
import { useLocation, Link } from 'react-router-dom'
import QueryString from 'querystring'

import { Box } from '../Shared/Base'
import { NavRenter } from './Renter'
import { NavOwner } from './Owner'
import { ClickAwayListener } from '@material-ui/core'
import routes from '../../routes'
import LinkSafe from '../Shared/LinkSafe'
import { useOpenMobileMenuLeftCallback } from '../Shared/hooks'
import { useImmerStore } from '../../store'
import { dataLayer } from '../../store/analytics.middleware'

const IconNav = () => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    fill="none"
    viewBox="0 0 20 18"
    className="nav"
  >
    <path
      fill="#919191"
      d="M18.667 10H1.333C.533 10 0 9.6 0 9s.533-1 1.333-1h17.334c.8 0 1.333.4 1.333 1s-.533 1-1.333 1zM19.167 2H.833C.333 2 0 1.6 0 1s.333-1 .833-1h18.334c.5 0 .833.4.833 1s-.333 1-.833 1zM19.167 18H.833C.333 18 0 17.6 0 17s.333-1 .833-1h18.334c.5 0 .833.4.833 1s-.333 1-.833 1z"
    />
  </Box>
)

const IconArrow = () => (
  <Box
    as="svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 18.5L12.5 12.5L4 6.5V18.5ZM13 6.5V18.5L21.5 12.5L13 6.5Z" fill="white" />
  </Box>
)

const HeaderStyled = styled.header`
  border-bottom: 1px solid var(--colorThin);
  display: flex;
  position: relative;
  padding: 30px 0;

  ._rent & {
    display: none;
  }

  ._homepage &,
  ._neuf &,
  ._agence &,
  ._mobilipass &,
  ._chasseur-location & {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;
    border-bottom: 0;
  }

  ._alert_result & {
    border-bottom: 0;
  }

  @media screen and (max-width: 1280px) {
    padding: 30px 20px;

    ._alert_dashboard & {
      padding: 30px 0;
    }
  }

  .nav {
    display: none;
    margin-right: 20px;

    ._alert_dashboard & {
      display: block;

      @media screen and (min-width: 767px) {
        display: none;
      }
    }
  }

  .arrow {
    fill: #919191;
    margin-top: 4px;
  }

  .logo {
    height: 62px;

    ._alert_dashboard & {
      height: 42px;

      @media screen and (min-width: 767px) {
        height: 62px;
      }
    }
  }
`

const HeaderNav = styled.nav`
  font-size: 1.8rem;

  a {
    color: var(--colorText);

    ._homepage &,
    ._neuf &,
    ._agence &,
    ._mobilipass &,
    ._chasseur-location & {
      color: var(--colorN_primary);
    }

    &:hover {
      color: var(--colorN_secondary);
    }
  }

  a.highlight {
    color: var(--colorN_secondary) !important;
    font-weight: bold;
  }

  li {
    position: relative;
  }

  .active {
    svg {
      transform: rotate(180deg);
    }
  }

  .app {
    background-color: var(--colorN_primary);
    color: white;
    border-radius: 5px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
  }

  a.pro {
    background-color: var(--colorN_secondary);
    color: white;
    border-radius: 5px;
    padding: 8px 20px;
    display: flex;
    align-items: center;

    ._agence &,
    ._chasseur-location & {
      display: none;
    }
  }
`

const HeaderNavSub = styled.ul`
  position: absolute;
  top: 32px;
  right: 0;
  padding: 0 20px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid var(--colorThin);
  min-width: 200px;
  display: none;
  z-index: 3;
  font-size: 16px;

  &{HeaderNav}.active & {
    display: block;
  }

  li {
    white-space: nowrap;
  }

  li + li {
    border-top: 1px solid var(--colorThin);
  }

  a, button {
    color: var(--colorText);
    padding: 15px 0;
    display: block;

    ._homepage &,
    ._neuf &,
    ._agence &,
    ._mobilipass &,
    ._chasseur-location &  {
      color: var(--colorN_primary);
    }

    &:hover,
    &:focus {
      color: var(--colorN_secondary);
    }
  }

  &:after,
  &:before {
    bottom: 100%;
    right: 40px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-bottom-color: white;
    border-width: 10px;
    margin-right: -10px;
  }

  &:before {
    border-bottom-color: var(--colorThin);
    border-width: 11px;
    margin-right: -11px;
  }
`

const HeaderMenu = ({ hide_signin }) => {
  const [
    {
      db: { me }
    },
    set_state
  ] = useImmerStore()
  const [open, set_open] = React.useState(null)
  const location = useLocation()
  const onClickSignup = React.useCallback(() => {
    dataLayer.push({
      event: 'eventGA',
      category: 'CTAInstall',
      action: 'HeaderSignup'
    })
    set_state(draft => {
      draft.ui.modal_app_install_open = true
    })
  }, [set_state])

  return (
    <ClickAwayListener onClickAway={() => set_open(false)}>
      <HeaderNav role="navigation">
        {me ? (
          <Box as="ul" display="flex" alignItems="center">
            {/* <Hidden smDown>
              <li>
                <Box
                  as="a"
                  display="flex"
                  alignItems="center"
                  href="https://loueragile.zendesk.com/hc/fr"
                  target="_blank"
                  rel="noopener noreferrer"
                  mr={30}
                >
                  Aide
                </Box>
              </li>
            </Hidden> */}
            <li className={open ? 'active' : ''}>
              <Box
                as="a"
                display="flex"
                alignItems="center"
                href="/user"
                onClick={e => {
                  e.preventDefault()
                  set_open(!open)
                }}
              >
                <Box as="span" mr={10}>
                  Mon compte
                </Box>
                <svg className="arrow" width={12} height={6}>
                  <use xlinkHref="#svg-arrow-global" />
                </svg>
              </Box>
              <HeaderNavSub
                onClick={e => {
                  set_open(!open)
                }}
              >
                {me.isOwner ? <NavOwner /> : <NavRenter />}
                <li>
                  <Link to={routes.logout.route.reverse()}>Se déconnecter</Link>
                </li>
              </HeaderNavSub>
            </li>
          </Box>
        ) : hide_signin ? null : (
          <>
            <Box as="ul" display="flex" alignItems="center">
              <Hidden smDown>
                <li>
                  <Link
                    to={{
                      pathname: routes.sign.route.reverse({
                        action: 'in'
                      }),
                      search: QueryString.stringify({
                        r_pathname: location.pathname,
                        r_search: location.search
                      })
                    }}
                  >
                    Connexion
                  </Link>
                </li>
                <li>
                  <Box ml={30} as="button" className="app" onClick={onClickSignup}>
                    Inscription
                  </Box>
                </li>
                <li>
                  <Box ml={30} as="a" href="/pro" className="pro">
                    <IconArrow />
                    <Box as="span" ml={10}>
                      Diffusez vos annonces
                    </Box>
                  </Box>
                </li>
              </Hidden>
              <Hidden mdUp>
                <li className={open ? 'active' : ''}>
                  <Box
                    as="a"
                    display="flex"
                    alignItems="center"
                    href="/user"
                    onClick={e => {
                      e.preventDefault()
                      set_open(!open)
                    }}
                  >
                    <Box as="span" mr={10}>
                      MENU
                    </Box>
                    <svg className="arrow" width={12} height={6}>
                      <use xlinkHref="#svg-arrow-global" />
                    </svg>
                  </Box>
                  <HeaderNavSub
                    onClick={e => {
                      set_open(!open)
                    }}
                  >
                    <li>
                      <Box as="button" onClick={onClickSignup}>
                        Inscription
                      </Box>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: routes.sign.route.reverse({
                            action: 'in'
                          }),
                          search: QueryString.stringify({
                            r_pathname: location.pathname,
                            r_search: location.search
                          })
                        }}
                      >
                        Connexion
                      </Link>
                    </li>
                    <li>
                      <Box mr={30} as="a" className="highlight" href="/pro">
                        Diffusez vos annonces
                      </Box>
                    </li>
                  </HeaderNavSub>
                </li>
              </Hidden>
            </Box>
          </>
        )}
      </HeaderNav>
    </ClickAwayListener>
  )
}

const HeaderSL = ({ layout_mw, hide_signin }) => {
  const onOpenMobileMenuLeftCallback = useOpenMobileMenuLeftCallback()

  return (
    <HeaderStyled className="header la_webview_hide">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        maxWidth={layout_mw}
        mx="auto"
      >
        <Box display="flex" alignItems="center">
          <Box as="button" mt="-3px" onClick={onOpenMobileMenuLeftCallback}>
            <IconNav />
          </Box>
          <Box as={LinkSafe} to={routes.homepage.route.reverse()}>
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1653311807/web/jinka/Logo-Jinka-a.svg"
              alt="Logo Jinka"
              className="logo"
              width="129px"
              mt="-10px"
            />
          </Box>
        </Box>
        <HeaderMenu hide_signin={hide_signin} />
      </Box>
    </HeaderStyled>
  )
}

const LayoutHeader = HeaderSL

export default LayoutHeader
