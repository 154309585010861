import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React from 'react'
import { simple_options } from '../../helpers/misc'
import { FormSelectMemoField } from '../Alert/Create/Settings.Form'
import { Box, Text } from '../Shared/Base'
import { FocusableField, useFormState } from '../Shared/Form/Field'
import { FormPhoneInput, FormTextFieldInput } from '../Shared/Form/Input'
import { valid_email, valid_phone, valid_required } from '../Shared/Form/validators'
import { useFetchApi } from '../Shared/hooks'
import ModalSimple from '../Shared/ModalSimple'

const software_options = simple_options([
  'ubiflow',
  'apimo',
  'ac3',
  'hektor',
  'netty',
  'agence-plus',
  'allyoucanpost',
  'adaptimmo',
  'autre'
])

const validators = {
  titre: { required: valid_required },
  integrateurs: { required: valid_required },
  contact_name: { required: valid_required },
  phone: { required: valid_required, is_phone: valid_phone('FR') },
  email: { required: valid_required, is_email: valid_email }
}
const LandingAgenceModal = ({ onClose, onCalendlyCreateClick }) => {
  const [success_message_visible, set_success_message_visible] = React.useState(false)
  const fetcher = useFetchApi()
  const form = useFormState({
    initial_values: {
      titre: '',
      integrateurs: '',
      contact_name: '',
      email: '',
      phone: ''
    },
    validators,
    onSubmitSuccess: React.useCallback(
      async values => {
        if (values.phone) {
          values.phone = parsePhoneNumberFromString(values.phone, 'FR').number
        }
        await fetcher('podio/lead_agency', { method: 'POST', body: JSON.stringify(values) })
        set_success_message_visible(true)
      },
      [fetcher]
    )
  })

  return (
    <ModalSimple onClose={onClose} maxSize="sm">
      {success_message_visible ? (
        <>
          <Box textAlign="center">
            <Box
              as="img"
              src="https://res.cloudinary.com/loueragile/image/upload/v1674555730/web/jinka/Illus-fusee-check.svg"
              alt="Illustration fusée"
              className="fls"
              display="block"
              margin="0 auto"
              width="350px"
            />
            <Text color="n_primary" className="fz-24" mt={30}>
              <strong>Merci !</strong>
            </Text>
            <Text color="n_primary" className="fz-24">
              Vous serez contacté rapidement <br /> sur le numéro indiqué.
            </Text>
          </Box>
        </>
      ) : (
        <>
          <Box display={{ ms: 'flex', xs: 'block' }} alignItems="stretch" gridGap={30}>
            <Box width={{ ms: '50%', xs: '100%' }} alignSelf="center" display="none">
              <Box textAlign="center" mt={20}>
                <Text className="fz-24" color="n_secondary">
                  <strong>Présentation de l’offre pro Jinka</strong>
                </Text>
                <Text className="fz-18" color="n_primary" mb={30}>
                  Réservez un créneau visio ou téléphonique avec un membre de l’équipe Jinka
                </Text>
                <Box
                  as="img"
                  src="https://res.cloudinary.com/loueragile/image/upload/v1674031414/web/jinka/Illus-fusee-calendar.svg"
                  alt="Illustration fusée"
                  className="fls"
                  display="block"
                  margin="0 auto"
                />
                <Box
                  as="a"
                  className="btn is-n_secondary is-big"
                  mt={50}
                  target="_blank"
                  rel="noopener noreferrer"
                  // onClick={onCalendlyCreateClick}
                  href="https://calendly.com/d/cn2-hhf-k8t/presentation-de-l-offre-pro-jinka"
                >
                  Réserver un créneau
                </Box>
              </Box>
            </Box>

            <Box
              display="none"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box borderLeft="1px solid" borderColor="thin" height="100%" />
              <Box
                border="1px solid"
                borderColor="thin"
                borderRadius={200}
                width="44px"
                height="44px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                className="fls"
                my={20}
              >
                <Text color="n_primary" fontSize={14}>
                  OU
                </Text>
              </Box>
              <Box borderLeft="1px solid" borderColor="thin" height="100%" />
            </Box>

            <Box
              as="form"
              onSubmit={form.onSubmit}
              onChange={form.onChange}
              width={{ ms: '100%', xs: '100%' }}
              py={{ ms: 30, xs: 0 }}
            >
              <Box display="grid" gridGap={15}>
                <Box textAlign="center">
                  <Text className="fz-24" color="n_secondary">
                    <strong>Essayez gratuitement notre offre</strong>
                  </Text>
                  <Text className="fz-18" color="n_primary" mb={15}>
                    Remplissez le formulaire ci-dessous pour que vos annonces soient diffusées
                    gratuitement sur Jinka.
                  </Text>
                </Box>
                <Box className="field">
                  <FocusableField
                    type="text"
                    showRequiredError={form.submittedOnce}
                    name="titre"
                    value={form.values.titre}
                    validators={form.validators.titre}
                    label="Nom de l’agence"
                    placeholder="La plus belle agence"
                  >
                    {FormTextFieldInput}
                  </FocusableField>
                </Box>

                <Box className="field">
                  <FocusableField
                    type="text"
                    showRequiredError={form.submittedOnce}
                    name="contact_name"
                    value={form.values.contact_name}
                    validators={form.validators.contact_name}
                    label="Nom"
                    placeholder="Julien Dupont"
                  >
                    {FormTextFieldInput}
                  </FocusableField>
                </Box>

                <FormSelectMemoField
                  mt={20}
                  name="integrateurs"
                  value={form.values.integrateurs}
                  label="Logiciel de diffusion"
                  hasPlaceholderStyleOnNoValue={false}
                  options={software_options}
                  validators={form.validators.integrateurs}
                  showRequiredError={form.submittedOnce}
                />

                <Box className="field">
                  <FocusableField
                    type="tel"
                    showRequiredError={form.submittedOnce}
                    name="phone"
                    label="Téléphone"
                    value={form.values.phone}
                    validators={form.validators.phone}
                    placeholder="06 12 34 56 78"
                  >
                    {FormPhoneInput}
                  </FocusableField>
                </Box>

                <Box className="field">
                  <FocusableField
                    showRequiredError={form.submittedOnce}
                    name="email"
                    value={form.values.email}
                    validators={form.validators.email}
                    label="E-mail"
                    placeholder="julien.dupont@laplusbelleagence.com"
                    errorLabels={{
                      required: 'Information obligatoire',
                      is_email: 'E-mail incorrect'
                    }}
                  >
                    {FormTextFieldInput}
                  </FocusableField>
                </Box>
              </Box>
              <Box textAlign="center">
                <Box
                  as="button"
                  type="submit"
                  className="btn is-n_secondary is-big"
                  mt={30}
                  disabled={form.loading}
                >
                  Envoyer
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </ModalSimple>
  )
}

export default LandingAgenceModal
