import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { Box, Text } from '../Shared/Base'

import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { simple_options } from '../../helpers/misc'
import { FormSelectMemoField } from '../Alert/Create/Settings.Form'
import { FocusableField, useFormState } from '../Shared/Form/Field'
import { FormPhoneInput, FormTextFieldInput, FormTextareaInput } from '../Shared/Form/Input'
import { useFetchApi } from '../Shared/hooks'
import { valid_email, valid_phone, valid_required } from '../Shared/Form/validators'

const LandingStyled = styled.main`
  background: linear-gradient(180deg, #753a92 21.85%, #a15ba3 72.35%);
  color: white;
  padding-bottom: 440px;

  label {
    font-weight: bold;
  }
`

const FirstBox = styled(Box)`
  position: relative;

  :before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    bottom: -440px;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url('https://res.cloudinary.com/loueragile/image/upload/v1698224268/web/jinka/Rent2023_BG.png');
    background-repeat: no-repeat;
    background-position: bottom;
  }

  @media screen and (max-width: 1110px) {
    br {
      display: none;
    }
  }
`

const software_options = simple_options([
  'ubiflow',
  'apimo',
  'ac3',
  'hektor',
  'netty',
  'agence-plus',
  'allyoucanpost',
  'adaptimmo',
  'autre'
])

const validators = {
  titre: { required: valid_required },
  integrateurs: { required: valid_required },
  contact_name: { required: valid_required },
  phone: { required: valid_required, is_phone: valid_phone('FR') },
  email: { required: valid_required, is_email: valid_email },
  comment: {}
}

const LandingRent = () => {
  const location = useLocation()
  const location_pathname = location.pathname

  const title = location_pathname === '/fnaim2023' ? 'FNAIM 2023' : 'RENT 2023'
  const [success_message_visible, set_success_message_visible] = React.useState(false) // TODO
  const fetcher = useFetchApi()
  const form = useFormState({
    initial_values: {
      titre: '',
      integrateurs: '',
      contact_name: '',
      email: '',
      phone: '',
      comment: ''
    },
    validators,
    onSubmitSuccess: React.useCallback(
      async values => {
        if (values.phone) {
          values.phone = parsePhoneNumberFromString(values.phone, 'FR').number
        }
        values.is_rent = true
        await fetcher('podio/lead_agency', { method: 'POST', body: JSON.stringify(values) })
        set_success_message_visible(true)
      },
      [fetcher]
    )
  })
  return (
    <LandingStyled>
      <Helmet>
        <title>{title} - Jinka</title>
        <meta name="description" content={`Jinka x ${title}`} />
        <meta property="og:title" content={`${title} - Jinka`} />
        <meta property="og:description" content={`Jinka x ${title}`} />
        <meta property="og:url" content="https://www.jinka.fr/" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/loueragile/image/upload/v1636016457/web/jinka/Image_OG.jpg"
        />
        <link rel="canonical" href="https://www.jinka.fr/" />
      </Helmet>

      <FirstBox
        as="section"
        maxWidth="100%"
        mx="auto"
        px={{ lg: 0, xs: 20 }}
        pt={{ lg: 100, xs: 50 }}
      >
        <Box maxWidth={1200} mx="auto" position="relative">
          <Box textAlign="center">
            <Box
              as="img"
              src={
                location_pathname === '/fnaim2023'
                  ? 'https://res.cloudinary.com/loueragile/image/upload/v1700670285/web/jinka/Fnaim2023.png'
                  : 'https://res.cloudinary.com/loueragile/image/upload/v1698222518/web/jinka/Rent2023.png'
              }
              alt=""
              className="fls"
            />
            <Text as="h1" color="#FFD338" fontSize={40} mt={35}>
              Offre {location_pathname === '/fnaim2023' ? 'FNAIM' : 'RENT'}
            </Text>
            <Box as="p" color="white" fontSize={35} mt={0}>
              1 mois de diffusion gratuite
            </Box>
          </Box>

          <Box bg="white" p={{ lg: 40, xs: 20 }} mt={35} borderRadius={20} maxWidth={520} mx="auto">
            {success_message_visible ? (
              <>
                <Box textAlign="center">
                  <Box
                    as="img"
                    src="https://res.cloudinary.com/loueragile/image/upload/v1674555730/web/jinka/Illus-fusee-check.svg"
                    alt="Illustration fusée"
                    className="fls"
                    display="block"
                    margin="0 auto"
                    width="350px"
                  />
                  <Text color="n_primary" className="fz-24" mt={30}>
                    <strong>Merci !</strong>
                  </Text>
                  <Text color="n_primary" className="fz-24">
                    Vous serez contacté rapidement sur le numéro indiqué.
                  </Text>
                </Box>
              </>
            ) : (
              <Box as="form" onSubmit={form.onSubmit} onChange={form.onChange}>
                <Box display="grid" gridGap={15}>
                  <Box textAlign="center">
                    <Text className="fz-24" color="n_secondary">
                      <strong>Essayez gratuitement notre offre</strong>
                    </Text>
                    <Text className="fz-18" color="n_primary" mb={15}>
                      Remplissez le formulaire ci-dessous pour que vos annonces soient diffusées
                      gratuitement sur Jinka.
                    </Text>
                  </Box>
                  <Box className="field">
                    <FocusableField
                      type="text"
                      showRequiredError={form.submittedOnce}
                      name="titre"
                      value={form.values.titre}
                      validators={form.validators.titre}
                      label="Nom de l’agence"
                      placeholder="La plus belle agence"
                    >
                      {FormTextFieldInput}
                    </FocusableField>
                  </Box>

                  <Box className="field">
                    <FocusableField
                      type="text"
                      showRequiredError={form.submittedOnce}
                      name="contact_name"
                      value={form.values.contact_name}
                      validators={form.validators.contact_name}
                      label="Nom"
                      placeholder="Julien Dupont"
                    >
                      {FormTextFieldInput}
                    </FocusableField>
                  </Box>

                  <FormSelectMemoField
                    mt={20}
                    name="integrateurs"
                    value={form.values.integrateurs}
                    label="Logiciel de diffusion"
                    hasPlaceholderStyleOnNoValue={false}
                    options={software_options}
                    validators={form.validators.integrateurs}
                    showRequiredError={form.submittedOnce}
                  />

                  <Box className="field">
                    <FocusableField
                      type="tel"
                      showRequiredError={form.submittedOnce}
                      name="phone"
                      label="Téléphone"
                      value={form.values.phone}
                      validators={form.validators.phone}
                      placeholder="06 12 34 56 78"
                    >
                      {FormPhoneInput}
                    </FocusableField>
                  </Box>

                  <Box className="field">
                    <FocusableField
                      showRequiredError={form.submittedOnce}
                      name="email"
                      value={form.values.email}
                      validators={form.validators.email}
                      label="E-mail"
                      placeholder="julien.dupont@laplusbelleagence.com"
                      errorLabels={{
                        required: 'Information obligatoire',
                        is_email: 'E-mail incorrect'
                      }}
                    >
                      {FormTextFieldInput}
                    </FocusableField>
                  </Box>

                  <Box className="field">
                    <FocusableField
                      type="text"
                      name="comment"
                      value={form.values.comment}
                      validators={form.validators.comment}
                      label="Commentaire"
                      placeholder="Informations complémentaires"
                    >
                      {FormTextareaInput}
                    </FocusableField>
                  </Box>
                </Box>
                <Box textAlign="center">
                  <Box
                    as="button"
                    type="submit"
                    className="btn is-n_secondary is-big"
                    mt={30}
                    disabled={form.loading}
                    width="100%"
                  >
                    Envoyer
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </FirstBox>
    </LandingStyled>
  )
}

export default LandingRent
